import React from 'react'
import "./Contac.css";

export const Contac = () => {
  return (
    <div class="contact_us_8">
  <div class="responsive-container-block container">
    <form class="form-box">
      <div class="container-block form-wrapper">
        <div class="responsive-container-block">
          <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-8 wk-ipadp-12" id="i10mt-9">
            <input class="input" id="ijowk-9" name="FirstName" placeholder="First Name"/>
          </div>
          <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-8 wk-ipadp-12" id="ieakm">
            <input class="input" id="indfi-7" name="Last Name" placeholder="Last Name"/>
          </div>
          <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-8 wk-ipadp-12" id="iajvf">
            <input class="input" id="ipmgh-9" name="Email" placeholder="Email"/>
          </div>
          <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-8 wk-ipadp-12" id="i0txn">
            <input class="input" id="imgis-8" name="PhoneNumber" placeholder="Phone Number"/>
          </div>
          <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-8 wk-ipadp-12" id="i634i-9">
            <textarea aria-placeholder="What’s on your mind?" class="textinput" id="i5vyy-9" placeholder="What’s on your mind?"></textarea>
          </div>
        </div>
        <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-8 wk-ipadp-12 checkbox-container" id="i634i-2-2">
          <div class="checkboc-container">
            <input id="i0m1w" type="checkbox"/>
            <p class="checkbox-text" id="i5ywz">
              are u sure ?
            </p>
          </div>
        </div>
        <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-8 wk-ipadp-12" id="i0txn-2">
          <button class="submit-btn">
            Submit
          </button>
        </div>
        <div class="right-side-text">
          <p class="text-blk contactus-head">
            Get in Touch
          </p>
          <p class="text-blk contactus-subhead">
          Address: salarpur , bhangel ,noida sector 102, pin code : 201304 , up
</p>
<p class="text-blk contactus-subhead">
Call Us: (+91) - 8227939571
</p>

<p class="text-blk contactus-subhead">
Email: alokswag5@gmail.com
</p>
         
         
        </div>
      </div>
    </form>
  </div>
</div>
  )
}
