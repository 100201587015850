import React from 'react'

function Shipping() {
  return (
    <div>

        <h1 className='text-center'>Shipping & Delivery Policy</h1>
        <h4 className='text-start m-5 align-items-center justify-content-center '>Here's a sample Shipping & Delivery Policy for your e-commerce website, OMU (spaceme.life):

---

<h1 className='text-start'>Shipping & Delivery Policy</h1>



Thank you for shopping with OMU! We are committed to delivering your orders quickly and efficiently. Please review our shipping and delivery policies below.

<h1 className='text-start'> Shipping Methods and Rates</h1>

We offer a variety of shipping options to meet your needs: <br/>

- Standard Shipping: Typically takes 5-7 business days. Rates vary based on location and weight of the package. <br/>
- Expedited Shipping: Typically takes 2-3 business days. Rates will be calculated at checkout. <br/>
- Express Shipping: Typically takes 1-2 business days. Rates will be calculated at checkout. <br/>

<h1 className='text-start'> Order Processing </h1>

- Orders are processed within 1-3 business days. <br/>
- You will receive a confirmation email once your order has been processed and shipped. <br/>
- Please allow additional time for order processing during peak seasons or promotional events.<br/>

<h1 className='text-start'>Delivery Locations</h1>

We currently ship to addresses within [insert countries or regions, e.g., the United States, Canada, etc.]. Unfortunately, we do not ship to P.O. boxes or APO/FPO addresses.

<h1 className='text-start'> Tracking Your Order </h1>

Once your order has shipped, you will receive an email with a tracking number so you can monitor your shipment's progress.

<h1 className='text-start'> Delivery Issues </h1>

If your order does not arrive within the estimated delivery time, please contact our customer support team at gmail: alokswag5@gmail.com  We will work with you to resolve any delivery issues as quickly as possible.



<h1 className='text-start'> Contact Us </h1>

If you have any questions or concerns about our shipping and delivery policy, please reach out to us at  contact email: alokswag5@gmail.com

Thank you for choosing OMU!

---
</h4>
    </div>
  )
}

export default Shipping